export interface ModuleType {
  name: string
  path: string
  redirectPage: boolean
  nextPage: (option?: string) => ModuleType | undefined
}

// ordered according to the subflow steps

export const Project: ModuleType = {
  name: 'project',
  path: '/project',
  redirectPage: false,
  nextPage: () => Informations
}

export const Informations: ModuleType = {
  name: 'informations',
  path: '/informations',
  redirectPage: false,
  nextPage: () => LegalStatus
}

export const LegalStatus: ModuleType = {
  name: 'legalStatus',
  path: '/legal-status',
  redirectPage: false,
  nextPage: () => Address
}

export const Address: ModuleType = {
  name: 'address',
  path: '/address',
  redirectPage: false,
  nextPage: () => MailsOptions
}

export const MailsOptions: ModuleType = {
  name: 'mailsOptions',
  path: '/mails-options',
  redirectPage: false,
  nextPage: (option) => (option === 'summary' ? Summary : Formalities)
}

export const Formalities: ModuleType = {
  name: 'formalities',
  path: '/formalities',
  redirectPage: false,
  nextPage: () => Summary
}

export const Summary: ModuleType = {
  name: 'summary',
  path: '/summary',
  redirectPage: false,
  nextPage: () => Payment
}

export const Payment: ModuleType = {
  name: 'payment',
  path: '/payment/summary',
  redirectPage: false,
  nextPage: () => Billing
}

export const Billing: ModuleType = {
  name: 'billing',
  path: '/payment/billing',
  redirectPage: false,
  nextPage: () => BillingProcessing
}

export const BillingProcessing: ModuleType = {
  name: 'billingProcessing',
  path: '/payment/processing',
  redirectPage: true,
  nextPage: () => Welcome
}

export const Welcome: ModuleType = {
  name: 'welcome',
  path: '/welcome',
  redirectPage: false,
  nextPage: () => undefined
}

export const Error: ModuleType = {
  name: 'error',
  path: '/error',
  redirectPage: true,
  nextPage: () => undefined
}

export const entryModule = (): ModuleType => {
  return Project
}

export const modules: ModuleType[] = [
  Address,
  Billing,
  BillingProcessing,
  Error,
  Formalities,
  Informations,
  LegalStatus,
  MailsOptions,
  Payment,
  Project,
  Summary,
  Welcome
]

export const domiciliationMainSteps = () => {
  return [Project, Informations, LegalStatus, Address, MailsOptions]
}

export const informationsMainSteps = () => {
  return [Formalities, Summary]
}

export const paymentMainSteps = () => [Payment, Billing]

export const commonFlowSteps = [
  Project,
  Informations,
  LegalStatus,
  Address,
  MailsOptions
]

export const paymentSteps = [Summary, Payment, Billing]
