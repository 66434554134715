import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/app/store'

export interface PaymentInformationsState {
  paymentFrequency?: string
  promoCodeError?: string
  intentType?: string
}

const initialState: PaymentInformationsState = {}

export const paymentInformationsSlice = createSlice({
  name: 'paymentInformations',
  initialState,
  reducers: {
    updatePaymentInformations: (
      state,
      action: PayloadAction<PaymentInformationsState>
    ) => {
      return { ...state, ...action.payload }
    }
  }
})

export const { updatePaymentInformations } = paymentInformationsSlice.actions
export const selectPaymentInformations = (state: RootState) =>
  state.paymentInformations

export default paymentInformationsSlice.reducer
