'use client'

import { useState, useEffect } from 'react'
import { useDispatch } from '@/hooks/redux'
import { setCountry } from '@/reducers/workflow'
import { Spin } from 'antd'

export default function CountryFlow({
  children
}: {
  children: React.ReactNode
}) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_HOST_FR === window.location.host) {
      dispatch(setCountry('FR'))
    } else if (process.env.NEXT_PUBLIC_APP_HOST_BE === window.location.host) {
      dispatch(setCountry('BE'))
    }

    setLoading(false)
  }, [])

  return loading ? (
    <div className='absolute top-[50%] left-[50%]'>
      <Spin size='large' />
    </div>
  ) : (
    children
  )
}
