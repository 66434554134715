import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/app/store'
import { CountryCode } from 'libphonenumber-js'

export interface SocietyInformationsState {
  phoneCountryCode?: CountryCode
  isMicro?: boolean
  formality?: boolean
  locale?: string
}

const initialState: SocietyInformationsState = {
  phoneCountryCode: 'FR'
}

export const societyInformationsSlice = createSlice({
  name: 'societyInformations',
  initialState,
  reducers: {
    updateInformations: (
      state,
      action: PayloadAction<SocietyInformationsState>
    ) => {
      return { ...state, ...action.payload }
    }
  }
})

export const { updateInformations } = societyInformationsSlice.actions
export const selectSocietyInformations = (state: RootState) =>
  state.societyInformations

export default societyInformationsSlice.reducer
