import { RestorePageDataType } from '@/graphql/queries/page/restore'
import {
  domiciliationMainSteps,
  informationsMainSteps,
  paymentMainSteps,
  ModuleType,
  modules
} from './modules'
import {
  CountryType,
  FlowType,
  goToInformations,
  goToSummary,
  goToWelcome,
  nextPage,
  setCountry,
  setFlowType
} from '@/reducers/workflow'
import { AppDispatch } from '../store'

export const generateFlow = (
  flow: string[],
  currentPath: string,
  nextPath: string
): string[] => {
  if (!flow.includes(nextPath)) {
    const followingPathIndex = flow.indexOf(currentPath) + 1
    const followingPath = flow.at(followingPathIndex)

    if (followingPath !== nextPath) flow = flow.slice(0, followingPathIndex)
    flow.push(nextPath)
  }

  return flow
}

export const findModule = (path: string) =>
  modules.find((mod) => mod.path === path)

export const flowUntil = (flowType: string, module: ModuleType): string[] => {
  const flowPaths = [
    ...domiciliationMainSteps(),
    ...informationsMainSteps(),
    ...paymentMainSteps()
  ].map((m) => m.path)
  return flowPaths.slice(0, flowPaths.indexOf(module.path) + 1)
}

export const restore = (dispatch: AppDispatch, data: RestorePageDataType) => {
  if (data.domiciliation.paid && data.contact.userPresent) {
    if (data.countryConfig.countryCode === 'FR') {
      window.location.assign(process.env.NEXT_PUBLIC_LOGIN_PAGE_FR as string)
      return
    } else if (data.countryConfig.countryCode === 'BE') {
      window.location.assign(process.env.NEXT_PUBLIC_LOGIN_PAGE_BE as string)
      return
    }
  }

  dispatch(setCountry(data.countryConfig.countryCode as CountryType))
  dispatch(setFlowType(data.society.projectType.toLowerCase() as FlowType))

  if (data.domiciliation.paid) {
    dispatch(goToWelcome())
  } else if (
    data.currentCart.purchases.find((p) => p.category === 'mail') &&
    data.currentCart.purchases.find((p) => p.category === 'domiciliation') &&
    data.currentCart.status === 'in_completion' &&
    data.society?.legalStatus &&
    data.contact.email &&
    data.contact.phoneNumber &&
    data.contact.firstname &&
    data.contact.lastname
  ) {
    dispatch(goToSummary())
  } else if (
    data.society?.projectType &&
    data.contact.email &&
    data.contact.phoneNumber &&
    data.contact.firstname &&
    data.contact.lastname
  ) {
    dispatch(goToInformations())
  } else {
    dispatch(nextPage())
  }
}
