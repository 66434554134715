import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/app/store'

export interface MarketingInformationState {
  amountWithVat?: number
}

const initialState: MarketingInformationState = {}

export const marketingInformationSlice = createSlice({
  name: 'marketingInformation',
  initialState,
  reducers: {
    updateMarketingInformation: (
      state,
      action: PayloadAction<MarketingInformationState>
    ) => {
      return { ...state, ...action.payload }
    }
  }
})

export const { updateMarketingInformation } = marketingInformationSlice.actions
export const selectMarketingInformation = (state: RootState) =>
  state.marketingInformation

export default marketingInformationSlice.reducer
